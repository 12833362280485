.ant-menu {
  .anticon {
    font-size: 15px !important;
  }
  .MuiSvgIcon-root {
    font-size: 18px !important;
  }
}
svg.anticon.nested-menu-icon {
  font-size: 10px !important;
  color: #f80;
}
.payment-icon {
  width: 40px;
  // border: 2px solid #d3c9c9;
}
.invoiceimage {
  background-image: url("../assets/images/top.png");
  width: 100%;
  height: 366px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.invoicelogo {
  height: 13%;
  position: absolute;
  top: 110px;
  left: 215px;
}
.invoicebtimage {
  background-image: url("../assets/images/Bottom.png");
  width: 100%;
  height: 255px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.invoice-description {
  height: 13%;
  position: absolute;
  top: 267px;
  left: 215px;
  table > tbody tr > td {
    padding-bottom: 0;
    span {
      color: #fff;
      font-weight: 600;
      font-size: 15px;
    }
  }
}
.invoice-title {
  height: 13%;
  position: absolute;
  top: 110px;
  right: 222px;
}

.highlight-default-card {
  padding: 10px 10px 0px 10px;
  background: #fff7e6;
  border: 1px solid #fff7e6;
}

.banner {
  background-image: url("../assets/images/Banner.jpg");
  border: 4px solid #fff;
  border-radius: 4px;
  background-size: cover;
}
.banner-bg {
  background-color: #fff7e6;
  opacity: 0.9;
  height: 55px;
}
.banner-text {
  color: #004570;
  padding: 6px;
  font-weight: 900;
  font-variant: petite-caps;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 36px;
}

.steps-action {
  margin-top: 24px;
}
.ant-divider-horizontal.ant-divider-with-text {
  font-weight: 600 !important;
  border-top-color: rgb(0 0 0 / 35%) !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #eff2ff !important;
}
.ant-table-small .ant-table-thead > tr > th {
  font-weight: 600;
}
.ant-select-selection-item {
  text-align: initial;
}
.err-margin .ant-form-item-explain-error > div {
  margin-left: 14%;
}
.table.table-bordered tbody tr td,
table.table.table-bordered thead tr th {
  padding: 12px 28px;
  border: 1px solid #ccc;
}
.tag-list {
  display: block !important;
  width: fit-content;
  margin-top: 2px !important;
}
.side-none {
  display: inline-block !important;
  margin: 6px 74px !important;
}
.tab-bg {
  background: #f0f0f0;
  padding: 10px;
  margin-top: -16px;
}

@primary-color: rgb(255, 136, 0);@layout-header-background: #FFF;@menu-item-color: #004570;@font-size-base: 13px;@layout-header-color: @primary-color;@heading-color: rgba(0, 0, 0, 0.95);@text-color: rgba(0, 0, 0, 0.72);@border-radius-base: 1px;@text-color-secondary: rgba(0, 0, 0, 0.60);@font-family: spezia,sans-serif;